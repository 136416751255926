<template>
  <Form
    :submit="onSubmit"
    :initialValues="initialValues"
    @change="handleFormChange"
  >
      <div class="form-narrow">
        <div class="form-row">
          <TextField name="partyId" label="party id*" :validate="composeValidators(required, uuid)" :editMode="editable" />
          <TextField name="communityId" label="community id*" :validate="composeValidators(required, uuid)" :editMode="editable" />
        </div>
        <div class="form-row">
          <DateInput label="engagement date*" name="engagementDate" :validate="required" :editMode="editable" :clear="false" />
          <DateInput label="disengagement date" name="disEngagementDate" :editMode="editable" :clear="formValues.disEngagementDate && editable" />
        </div>
        <ModalFooter :footer="footer" :tertiary="cancel" />
      </div>
  </Form>
</template>

<script>
  import Form from "@/components/form/Form";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import TextField from "@/components/form/TextField";
  import DateInput from "@/components/form/DateInput";
  import ModalFooter from "@/components/ui/modals/ModalFooter";

  export default {
    name: "MembershipForm",
    components: {
      Form,
      TextField,
      DateInput,
      ModalFooter
    },
    mixins: [
      ValidatorMixin,
      NotifyMixin
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['close'],
    data() {
      return {
        formValues: {},
      };
    },
    computed: {
      editable() {
        return !this.initialValues.deletedAt;
      },
      footer() {
        if (this.editable) {
          return {
            primaryButton: 'save',
            tertiaryButton: 'cancel'
          };
        }

        return {
          tertiaryButton: 'back'
        };
      }
    },
    methods: {
      handleFormChange(state) {
        this.formValues = state.values;
      },
      cancel(e) {
        e.preventDefault();
        this.$emit('close');
      },
    },
  }
</script>
<style scoped>
  .field-disEngagementDate :deep(.form-col) {
    margin-right: 0;
  }
</style>

